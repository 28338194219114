.container {
  transition: max-height 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.fader {
  position: absolute;
  height: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.toggle {
  position: absolute;
  right: 0;
  bottom: 0;
}
