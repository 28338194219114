.container {
  display: flex;
}

.small {
  a {
    font-size: 20px;
    margin: 0px 10px;
    color: black;
    &:hover {
      color: black;
    }
  }
}

.large {
  line-height: 60px;
  a {
    font-size: 32px;
    margin: 0px 20px;
    color: white;
    &:hover {
      color: white;
    }
  }
}

.selected {
  font-weight: bold;
}

.large {
  .selected {
    border-bottom: 3px solid #be00f3;
  }
}

.small {
  .selected {
    border-bottom: 2px solid black;
  }
}
