@import "~src/styles";

.navContainer {
  display: flex;
  flex-direction: column;
}

.navLink {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  display: block;
  padding: 10px;
  border-radius: $borderRadiusBase;
  border: 1px solid transparent;

  &:hover {
    background: white;
    border-color: #f0f0f0;
  }

  span[role=img] {
    margin-right: 15px;
  }
}

.active {
  color: $primaryColor !important;
  composes: navLink;
}

.icon {
  width: 40px;
}

.popup {
  background: darken($primaryColor, 50%);
  padding: 0 15px 0 0;
  border-radius: $borderRadiusBase;
}