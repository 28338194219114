.container {
  background: white;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #eaeaea;
  color: #999;

  a {
    display: block;
    padding: 8px;

    &:not(:first-child) {
      border-left: 1px solid #eaeaea;
    }
  }

  img {
    width: 24px;
    filter: grayscale(100%) opacity(60%);
  }
}