@import '~src/styles';

/* @font-face {
  font-family: 'Mont';
  src: url('mont-regular.woff2') format('woff2'),
    url('mont-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
 */
body {
  margin: 0;
  background: white;
  color: black;
  font-family: "Open Sans", "Helvetica", "Trebuchet MS", "Arial", -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 28px;
}

h1, h2 {
  font-weight: bold !important;
}

p {
  font-size: 15px;
}

::placeholder {
  color: #888 !important;
  font-size: 16px !important;
}


/**
 * Ant Design overrides
 */

.ant-layout {
  background: #f8f8f8;
}

.ant-card-head {
  border-bottom-color: #e8e8e8;
}

.ant-card-cover img {
  border-radius: 0;
}

.ant-card-meta-description {
  color: #222;
}

.ant-table {
  overflow: hidden; // Fix white line at bottom of table due to border radius
}

.ant-table-thead > tr > th {
  font-weight: 600;
}

.ant-pagination {
  color: white;
  font-weight: 600;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next,
.ant-pagination-item-ellipsis {
  color: white !important;
}

.ant-menu {
  font-weight: 600;
}