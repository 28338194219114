.small {
  height: 16px;
}

.medium {
  height: 20px;
}

.large {
  height: 24px;
}