@import '~src/styles';

.headerContainer {
  background: #fff;
  padding: 0 50px;
  box-shadow: 0px 1px 2px 0px rgba(50, 50, 50, 0.1);
}

.header {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: $maxWidth;
}

.hypeyLogo {
  display: flex;
  align-items: center;
  font-family: 'Dancing Script', 'Open Sans', sans-serif;
  font-size: 38px;
  font-weight: bold;

  a {
    color: black;
    font-weight: bold;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: $borderRadiusBase;
  img {
    height: 42px;
  }
}

.contentContainer {
  padding: 25px 50px;
}

.content {
  min-height: 80vh;
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;
}

.footer {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

@media only screen and (max-width: 768px) {
  .headerContainer {
    padding: 0 25px;
  }

  .contentContainer {
    padding: 25px;
  }
}